import React from "react";

const howItWorksData = [
  {
    imgSrc: "/Assets/image/HowItWorks/1.svg",
    heading: "Filter by sector/product add result to cart",
    description:
      "The user searches for rental properties, browses videos, and adds upto 3 videos to the cart.",
  },
  {
    imgSrc: "/Assets/image/HowItWorks/2.svg",
    heading: "get access to 10 owners contact"
,    description:
      "After adding 3 videos, the user is prompted to view the cart, enters their name and phone number, and proceeds.",
  },
  {
    imgSrc: "/Assets/image/HowItWorks/3.svg",
    heading: "Pay to get owner contact details",
    description:
      "The user pays ₹99, and after payment, receives the contact details of the 3 owners both on the screen and via WhatsApp.",
  },
];

function HowItWorks() {
  return (
    <div className="container mt-0 mb-5  d-flex flex-column align-items-center justify-content-center position-relative">
      {/* <div
        className="display-4 text-center position-relative"
        style={{ zIndex: 0 }}
      >
        Get Owner/Broker's Details
      </div> */}
      {/* <h5>(In just 3 Simple Steps)</h5> */}
      <div className="fw-semibold" style={{fontSize: "clamp(24px, 8vw, 52px)"}}>How it Works</div>
      <div
        className="d-flex justify-content-between position-relative p-2"
        style={{ zIndex: 0, gap: "5vw", maxWidth: "90vw" }}
      >
        <div
        className="position-absolute d-flex top-50 d-none-1434 start-50"
        style={{ gap: "15rem", zIndex: 10, transform: "translate(-50%, -50%)" }}
      >
        <img
          src="/Assets/image/HowItWorks/arrow1.svg"
          alt="arrow"
          className="h-auto w-auto"
          style={{ maxHeight: "4rem" }}
        />
        <img
          src="/Assets/image/HowItWorks/arrow2.svg"
          alt="arrow"
          className="h-auto w-auto"
          style={{ maxHeight: "4rem" }}
        />
      </div>
        {howItWorksData.map((item, index) => (
          <div
            className="shadow rounded-4 p-md-4 p-1 text-center vstack align-items-center"
            style={{ maxWidth: "355px", flex: 1 }}
          >
            <img
              src={item.imgSrc}
              alt="Search Icon"
              className="mx-auto mb-3 mt-4"
              style={{ width: "5vw", minWidth: "2rem", aspectRatio: 1 }}
            />
            <h5 className="card-title fw-md-semibold" style={{fontSize: "clamp(12px, 2vw, 24px)"}}>{item.heading}</h5>
            {/* <p className="card-text d-lg-block d-none">{item.description}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HowItWorks;
