import React, { memo } from "react";
import PrimaryBtn from "../Buttons/PrimaryBtn";
import { borderRadius, padding } from "@mui/system";

const SavingPopUp = memo(({ type, savings, closePopUp }) => {
  console.log("typecategoryId: ", type);

  const categoryRates = {
    PG: 1,
    "Zero B & S": 2,
    "1 RK": 3,
    "1 BHK": 4,
    "2 BHK": 5,
    "3 BHK": 6,
    "4 BHK": 7,
    "5 BHK & Above": 8,
    "Market Place": 9,
    "Short Stay": 10,
    "party-places-&-farm-houses": 11,
    All: 12,
  };

  
  const pricesForRentalDetails = {
    [categoryRates["PG"]]: 200,
    [categoryRates["1 RK"]]: 500,
    [categoryRates["1 BHK"]]: 700,
    [categoryRates["2 BHK"]]: 200,
    [categoryRates["3 BHK"]]: 300,
    [categoryRates["4 BHK"]]: 400,
    [categoryRates["Short Stay"]]: 25,
    [categoryRates["party-places-&-farm-houses"]]: 300,
  };
  
  // Function to get category name from ID
  const getCategoryName = (id) => {
    return Object.keys(categoryRates).find((key) => categoryRates[key] === id) || "Unknown";
  };


  const typeId = parseInt(type, 10);

  // Get category name and price dynamically
  const categoryName = getCategoryName(typeId);
  const price = pricesForRentalDetails[typeId] || 200; // Default ₹200 if not found
  

  return (
    <div
      className="position-fixed d-flex justify-content-center align-items-center"
      style={{ inset: 0, backgroundColor: "rgba(0,0,0,0.5)", zIndex: 200 }}
    >
{/* 
      <span style={{ color: "red" }}>
          {getKeyByValueFromObj(propertyTypes)(selectadedata.categoryId)}
        </span> */}
      <div
        className="overflow-hidden bg-white"
        style={{
          borderRadius: 27.2,
          width: 356,
          maxWidth: "90vw",
          transform: "translate(-50% -50%)",
        }}
      >
        <img
          src="/Assets/image/piggyBank.png"
          alt="piggy bank"
          className="ms-auto d-block"
          style={{ width: "80%" }}
        />
        <div className="p-3 text-center">
          {/* <div className="d-flex flex-column gap-0 fw-bold">
            <p className="m-0" style={{ fontSize: 31.87 }}>
              In {type} you are
            </p>
            <p className="m-0" style={{ fontSize: 36 }}>
              Saving Rs {savings}
            </p>
          </div>
          <p style={{ fontSize: 18 }}>
            {type.toLowerCase() == "3 bhk" ||
            type.toLowerCase() == "4 bhk" ||
            type.toLowerCase() == "2 bhk" ? (
              <>
                We connect you with <strong className="text-dark">owners and brokers</strong>, owners charge no fees,
                and{" "}
                <strong className="text-danger">
                  brokers receive their brokerage from their respective owners
                </strong>
                .
              </>
            ) : (
              <>
                With NO BROKERAGE, We will directly connect you to the owner!!
              </>
            )}
          </p> */}
           {/* This is a <span style={{ color: "red" }}>{categoryName}</span> message. Dear User, for{" "}
      <span style={{ color: "red" }}>{categoryName}</span>, select 10 or fewer owner-listed videos and pay just{" "}
      <span style={{ color: "red" }}>₹{price}</span> to eliminate 15 days of brokerage expenses. Start saving today! */}
      <h4>Say No to Brokerage</h4>
      Select up to 10 videos, pay just <span style={{ color: "red" }}>₹{price } </span>
      and save 15 days of brokerage! Start saving today and rent smarter.
          <PrimaryBtn
            className="w-100"
            style={{ borderRadius: 11.5, padding: 9.58 }}
            onClick={closePopUp}
          >
            Continue
          </PrimaryBtn>
        </div>
      </div>
    </div>
  );
});

export default SavingPopUp;
