import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import {
  pricesForRentalDetails,
  propertyTypes,
} from "../../constants/pricingDetails";
import {
  countRepeatingItems,
  getKeyByValueFromObj,
} from "../../functions/helperFunction";
import { wishlistItemCount } from "../../Contexts/WishListContext";

const getKeyByValue = getKeyByValueFromObj(propertyTypes);

const OrderDetails = memo(() => {
  const { data } = useContext(wishlistItemCount);
  const [showDetails, setShowDetails] = useState(false);
  const itemsFromWishlist = useMemo(() => Array.from(data), [data]);

  const totalRent = itemsFromWishlist.reduce((sum, item) => sum + Number(item.rent || 0), 0);

  // Get the number of rent values
  const rentCount = itemsFromWishlist.length > 0 ? itemsFromWishlist.filter(item => item.rent).length : 1;
  
  // Final calculation
  const finalValue = totalRent / rentCount / 2;
  
    
  

  const toggleDetailsPopup = useCallback(() => {
    setShowDetails((prevState) => !prevState);
  }, [setShowDetails]);

  const orderSummery = useMemo(() => {
    const categories = itemsFromWishlist.length > 0 
      ? itemsFromWishlist.map((item) => Number(item.categoryId))
      : [Object.keys(pricesForRentalDetails)[0]]; 
  
    console.log("categories: ", categories);
  
    // Filter out category 10, 5, and 6 for max calculation
    const validCategories = categories.filter((cat) => ![10, 5, 6].includes(cat));
  
    const maxCategoryPrice = validCategories.length > 0
      ? Math.max(...validCategories.map((cat) => pricesForRentalDetails[cat] || 0))
      : 0;
  
    const sumCategoryPrice = categories.reduce((sum, cat) => sum + (pricesForRentalDetails[cat] || 0), 0);
  
    // If all selected categories are only 10, 5, or 6, use sum instead of max
    const finalAmount = categories.every((cat) => [10, 5, 6].includes(cat)) ? sumCategoryPrice : maxCategoryPrice;
  
    const calculatedAmount = data.size >= 1 ? finalAmount : finalAmount * 3;
  
    const totalAmount = Math.max(calculatedAmount);
  
    return { totalAmount, categories };
  }, [data, pricesForRentalDetails, itemsFromWishlist]);

  const orderDetails = useMemo(() => {
    const repeatedCategoryObject = countRepeatingItems(orderSummery.categories);
    return Object.keys(repeatedCategoryObject).map((item, index) => {
      const priceOFCategory = pricesForRentalDetails[item];
      return (
        <div
          key={index}
          className="d-flex justify-content-between fs-6 fw-bold"
        >
          {/* <div className="categoryName">{getKeyByValue(item)}</div>
          <div>
            {priceOFCategory} x {repeatedCategoryObject[item]} ={" "}
            {repeatedCategoryObject[item] * priceOFCategory}
          </div> */}
        </div>
      );
    });
  }, [data]);

  return (
    <div
      className="bg-white w-100 p-3 shadow position-relative"
      style={{ maxWidth: "554px", minWidth: "333px", borderRadius: 19.29 }}
    >
      {showDetails && (
        <div
          className="position-absolute bg-white shadow overflow-hidden"
          style={{
            bottom: 0,
            right: 0,
            width: "90%",
            borderRadius: "1.5rem",
            zIndex: 10,
          }}
        >
          <div
            className="text-end pe-4 cursor-pointer"
            onClick={toggleDetailsPopup}
            style={{ backgroundColor: "rgba(0,0,0,0.25)", fontSize: 30 }}
          >
            &times;
          </div>
          <p className="px-4 pt-2">
            The price is fixed upto 5 properties. For orders exceeding 5 properties,
            additional price will be charged as per the property.
          </p>
          <div className="px-4">
            <p className="fw-bold">Property rates (per property):</p>
            <ul>
              {Object.keys(pricesForRentalDetails).map((property) => (
                <li key={property}>
                  {getKeyByValueFromObj(propertyTypes)(property)}: ₹{" "}
                  {pricesForRentalDetails[property]}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {/* <p className="d-flex justify-content-between" role="heading">
        <span className="fw-bold fs-5">Bill Details</span>
        {data.size < 5 && (
          <span
            className="d-flex align-items-center gap-2 cursor-pointer"
            onClick={toggleDetailsPopup}
          >
            Why {orderSummery.totalAmount}?
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="black"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-badge-info"
            >
              <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
              <line x1="12" x2="12" y1="16" y2="12" />
              <line x1="12" x2="12.01" y1="8" y2="8" />
            </svg>
          </span>
        )}
      </p> */}
      {data.size >= 5 && <div>{orderDetails}</div>}
      <hr />
      <p className="d-flex justify-content-between w-100 fw-bolder">
        <span>To Pay</span>{" "}
        <span className="text-success" style={{ letterSpacing: 2 }}>
          ₹{orderSummery.totalAmount}
        </span>
      </p>
      <div
        className="savingAmtSection rounded-pill d-flex justify-content-between align-items-center px-3 p-2"
        key={orderSummery.brokerage}
        style={{ backgroundColor: "rgba(28, 166, 114, 0.19)" }}
      >
        <p className="p-2 mb-0">
          Your total savings is at least{" "}
          <span className="text-success fw-bold shakeSavings" style={{ fontSize: "1.1em" }}>
            ₹{" "}
       
            {finalValue -orderSummery.totalAmount}
          </span>{" "}
          (15 days of brokerage).
        </p>
      </div>
    </div>
  );
});

export default OrderDetails;