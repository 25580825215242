// Category.js

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import axios from "axios";
import Shimmer from "./Shimmer";
import { useNavigate } from "react-router-dom";

const   Category = (props) => {
  const [counteron, setcounteron] = useState(false);
  const [category, setcategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    try {
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getData",
          table: "categories",
          column: "status",
          value: "1",
          orderColumn: "sort_order",
          ordervalue: "Desc",
        },
        { headers: headers }
      );
      setcategory(response.data.data);
      console.log("aa12", response.data.data);

    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 6, 
    slidesToScroll: 1, 
  
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: false,
          infinite: true, 
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 3,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 770,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 4.2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 3,
          slidesToScroll: 1, // Adjusted
        },
      },
      {
        breakpoint: 350,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 2, // Adjusted
          slidesToScroll: 1, // Adjusted
        },
      },
    ],
  };
  

  if (category.length === 0) {
    return <Shimmer />;
  }
  // console.log(category)
  // const categoriesToDisplay = category.filter(item => item.categoryId == 1 || item.categoryId == 4)
  return (
    <ScrollTrigger onEnter={() => setcounteron(true)}>
      <div className="col-md-12 slider my-3 d-flex justify-content-center ">
       <div className="col-md-2"></div>
       <div className="col-md-9 col-12">
          <Slider className="ourclientsvs" {...settings}>
          {category
  .filter((categorys) => ![ "12" ].includes(categorys.categoryId))
  .map((categorys) => (
    <div key={categorys.categoryId}>
      <div
        className="clientimg"
        onClick={() => {
          const constructedUrl = `/${categorys.name.replace(/ /g, "-")}`;
          const smallurl = constructedUrl.toLowerCase();
          categorys.categoryId === "12"
            ? props.onCategrySelected(categorys.categoryId)
            : navigate(smallurl, {
                state: {
                  id: categorys.categoryId,
                  name: categorys.name,
                },
              });
        }}
      >
        <div className="category d-flex flex-column align-items-center position-relative cursor-pointer">
          <img
            src={
              "https://bookmyqrcode.com/home_rental/" + categorys.image
            }
            alt=""
          />
          <h4 className="position-absolute top-50 start-50 translate-middle sliderh4">
            {counteron && (
              <CountUp
                start={0}
                end={
                  categorys.name === "All"
                    ? categorys.all_count
                    : categorys.category_count
                }
                duration={1}
                delay={0}
              />
            )}
          </h4>
        </div>
        <div className="categoryname">
          <h5 className="my-2">{categorys.name}</h5>
        </div>
      </div>
    </div>
  ))}

          </Slider>
        </div>
        <div className="col-md-1"></div>
      </div>
    </ScrollTrigger>
  );
};

export default Category;
