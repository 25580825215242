// Pg client 33/- Pg owner 33/-
// 1RK client 49/- 1RK owners 49/-
// 1bhk client 69/- 1bhk owner 69/-
// 2, 3, 4 bhk if owner property 99/- n zero for broker property and broker can buy client details 99/-

export const propertyTypes = {
  //categoryName:categoryID
  PG: 1,
  "Zero B & S": 2,
  "1 RK": 3,
  "1 BHK": 4,
  "2 BHK": 5,
  "3 BHK": 6,
  "4 BHK": 7,
  "5 BHK & Above": 8,
  "Market Place": 9,
  "Short Stay": 10,
  "party-places-&-farm-houses": 11,
  All: 12,
};

export const pricesForRentalDetails = {
  //categoryID:Price
  [propertyTypes["PG"]]: 200,
  [propertyTypes["1 RK"]]: 500,
  [propertyTypes["1 BHK"]]: 700,
  [propertyTypes["2 BHK"]]: 200,
  [propertyTypes["3 BHK"]]: 300,
  [propertyTypes["4 BHK"]]: 400,
  [propertyTypes["Short Stay"]]: 25,
  [propertyTypes["party-places-&-farm-houses"]]: 0,
};

export const minNumberOfOrdersAllowedToGetOwnerDetails = 5;

export const savingsByType = {
  [propertyTypes["PG"]]: "4k - 6k",
  [propertyTypes["1 RK"]]: "6k - 8k",
  [propertyTypes["1 BHK"]]: "11k - 13k",
  [propertyTypes["2 BHK"]]: "15k - 20k",
  [propertyTypes["3 BHK"]]: "15k - 28k",
  [propertyTypes["4 BHK"]]: "22k - 34k",
};
