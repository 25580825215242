import React, { memo } from "react";
import { propertyTypes } from "../../constants/pricingDetails";

function getKeyByValue(value) {
  // will return the categroyName if we pass categoryID

  return Object.keys(propertyTypes).find((key) => propertyTypes[key] == value);
}

const CartItemDetails = memo(({ data, ownerBrokerDetails }) => {

  return (
    <div
      className="rounded-4 mx-auto d-flex flex-md-row flex-column p-3 gap-3 my-3 align-items-center shadow cartItemDetails"
      style={{ width: "90vw", maxWidth: 1024 }}
    >
      <div className="video" style={{ maxHeight: "500px", height: "100%" }}>
        <iframe
          src={data.video_link}
          title="video"
          style={{ width: "100%", height: "500px", borderRadius: 8 }}
          allowFullScreen
        ></iframe>
      </div>
      <div
        className="right flex-grow-1 d-flex flex-column gap-2 video"
        style={{ flex: 1, height: "fit-content" }}
      >
        <div className="d-flex gap-4 align-items-center justify-content-between">
          <h4 className="fw-bold">{data.title}</h4>
          <h6 className="uniqueId">{data.inventroyId}</h6>
        </div>
        <div className="d-flex gap-2 align-items-stretch ">
          <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2 w-50">
            <h6 className="fw-bold">Price:</h6>
            <p className="text-center">{data.rent}</p>
          </div>
          <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2  w-50">
            <h6 className="fw-bold">Category:</h6>
            <p className="text-center">{getKeyByValue(data.categoryId)}</p>
          </div>
        </div>
        <div className="d-flex gap-2 align-items-stretch ">
          <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2  w-50">
            <h6 className="fw-bold">Location:</h6>
            <p className="text-center">
              {data.area_name}, {data.city_name}
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center rounded-4 border border-black p-2 w-50">
            <h6 className="fw-bold">Furnished:</h6>
            <p className="text-center">Fully Furnished</p>
          </div>
        </div>
        <div className="d-flex gap-2 align-items-stretch ">
          <div className="d-flex flex-column  justify-content-center align-items-center rounded-4 border border-black p-2  w-50">
            <h6 className="fw-bold">Interested:</h6>
            <p className="text-center">{data.enquiryCount}</p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center rounded-4 border border-black p-2 w-50">
            <h6 className="fw-bold">Partner</h6>
            <p className="text-center">
            {data.broker_name}
            </p>
          </div>
        </div>
        {ownerBrokerDetails?.name != data.broker_name && (
          <div style={{ fontSize: "clamp(16px, 4vw, 30px)" }}>
            <strong className="text-danger">Owner Name: </strong>
            {data.broker_name}
          </div>
        )}
        <div style={{ fontSize: "clamp(16px, 4vw, 30px)" }}>
          <strong className="text-danger">Owner Number: </strong>
          {data.broker_number}
          </div>
      </div>
    </div>
  );
});

export default CartItemDetails;
